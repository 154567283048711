import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import Header from './header'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `2rem 1.0875rem 1.45rem`,
          }}
        >
          {children}
          <footer className="footer">
            <p>
              <Link to="/terms/">利用規約</Link>
            </p>
            <p>
              <Link to="/privacy/">プライバシーポリシー</Link>
            </p>
            <p>
              <Link to="/notation/">特定商取引法に基づく表記</Link>
            </p>
            <p>
              <Link to="/contact/">お問い合わせ</Link>
            </p>
            <p>
              <Link to="/faq/">よくある質問</Link>
            </p>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
